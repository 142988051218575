<template>
  <div class="main-uniteApproved">
      <!--顶部-->
      <div class="top-search-add">
        <searchFrom @searchFromData="searchFromData"/>
        <el-button type="primary" @click="toAddUnit">新增单位</el-button>
      </div>
      <!--表格部分-->
      <div class="bottom-table">
        <el-table v-loading="loading" max-height="550" :data="tableData" border :header-cell-style="headerCellStyle">
          <template slot="empty">
            <IsEmpty />
          </template>
          <el-table-column type="index" label="序号" width="50" align="center" />
          <el-table-column prop="name" label="单位名称" align="center" show-overflow-tooltip />
          <el-table-column prop="type" label="单位类别"  align="center" >
            <template slot-scope="scope">
              {{ ['', '企业', '政府'][scope.row.type] }}
            </template>
          </el-table-column>
          <el-table-column prop="characteristic" label="单位性质" align="center" >
            <template slot-scope="scope">
              {{ getContent(SIGNOPTIONS, scope.row.characteristic, 'label', 'text') }}
            </template>
          </el-table-column>
          <el-table-column prop="administrativeDivisionName" label="行政区划" align="center" :show-overflow-tooltip="true" />
          <el-table-column prop="contactPerson" label="单位联系人"  align="center"  />
          <el-table-column prop="contactPhone" label="联系电话"  align="center"  />
          <el-table-column prop="unitPhone" label="单位主账号"  align="center"  />
          <el-table-column prop="accountState" label="账号状态"  align="center" >
            <template slot-scope="scope">
              {{ ['正常', '禁用'][scope.row.disable] }}
            </template>
          </el-table-column>
          <el-table-column prop="address6" label="操作"  width="300px" align="center" fixed="right">
            <template slot-scope="scope">
              <el-button type="text" @click="toDetail(scope.row)">查看</el-button>
              <el-button type="text" @click="toEditUnit(scope.row)">编辑</el-button>
              <el-button type="text" v-if="scope.row.type === 1" @click="cancleDeregulation(scope.row)">取消监管</el-button>
              <span style="display: inline-block;margin: 0 10px" v-if="scope.row.type === 2">
                 <el-button type="text" style="color: #FF5A59" v-if="scope.row.disable === 0" @click="handlerStopUserCount(scope.row, 1)">停用</el-button>
              <el-button type="text" v-else @click="handlerStopUserCount(scope.row, 0)">启用</el-button>
              </span>
              <el-button type="text" @click="repassword(scope.row)">重置密码</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="queryParams.pageNum"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="queryParams.pageSize"
          layout="total, sizes, prev, pager, next"
          :total="total"
        >
        </el-pagination>
      </div>
      <deregulationDilog @reloadPage="reloadPage" :deregulation="deregulation" :deregulationVisible.sync="deregulationVisible"/>
      <repasswordDilog :repasswordParams="repasswordParams" :repasswordVisible.sync="repasswordVisible"/>
      <stopOrEnableUserCount @reloadPage="reloadPage" :changeCountParams="changeCountParams" :stopOrEnableUserVisible.sync="stopOrEnableUserVisible"/>
  </div>
</template>

<script>
  import searchFrom from "./searchForm"
  import deregulationDilog from "./deregulationDilog";
  import repasswordDilog from "./repasswordDilog";
  import stopOrEnableUserCount from "./stopOrEnableUserCount";
  import { getContent } from '@/utils/index'
  import { SIGNOPTIONS } from '../dataDictionary'
  import { getAuditSubordinateGovernmentPageList } from "@/api/unitManage"
  export default {
    name: "unitApproved",
    components: {
      searchFrom,
      deregulationDilog,
      repasswordDilog,
      stopOrEnableUserCount
    },
    props: ['currentTreeId'],
    data() {
      return {
        SIGNOPTIONS,
        repasswordParams: {}, //重置密码参数
        deregulation: {},  //取消监管参数'
        changeCountParams: {}, //启用、停用账号参数
        deregulationVisible: false, //取消监管弹框
        repasswordVisible: false, //重置密码弹框
        stopOrEnableUserVisible: false, //停用/启用账号
        loading: false,
        total: 10,
        tableData: [],
        queryParams: { //查询参数
          pageNum: 1,
          pageSize: 10
        }
      }
    },
    watch: {
      currentTreeId: {
        handler(val) {
          this.queryParams.auditPassId = val
          this.getList()
        }
      }
    },
    mounted() {
      this.getList()
    },
    methods: {
      getContent,
      reloadPage() { //重新加载页面
        this.getList()
      },
      //停用/启用账号
      handlerStopUserCount(item, status) {
        this.changeCountParams = {
          status: status,
          name: item.unitPhone,
          systemId: item.systemId,
          id: item.id
        }
        this.stopOrEnableUserVisible = true
      },
      /*查询参数*/
      searchFromData(data) {
       this.queryParams = {...this.queryParams, ...data}
       this.queryParams.pageNum = 1
      // this.queryParams.auditPassId = ''
        this.getList()
      },
      handleSizeChange(pageSize) {
        this.queryParams.pageSize = pageSize
        this.queryParams.pageNum = 1
        this.getList()
      },
      handleCurrentChange(pageNum) {
        this.queryParams.pageNum = pageNum
        this.getList()
      },
    //  新增
      toAddUnit() {
        this.$router.push({path: '/baseSetting/unitAdd'})
      },
      //查看
      toDetail(row) {
        console.log(row)
        this.$router.push({ path: '/baseSetting/unitInfoDetail', query: { id: row.id, type: row.type, tabs: 'first' } })
      },
    //  编辑
      toEditUnit (row) {
        this.$router.push({path: '/baseSetting/unitEdit',query: { id: row.id, type: row.type } })
      },
    //  取消监管
      cancleDeregulation(row) {
        this.deregulation.id = row.id
        this.deregulation.type = row.type
        this.deregulationVisible = true
      },
      //  重置密码
      repassword(item) {
        this.repasswordParams = {
          name: item.unitPhone,
          systemId: item.systemId,
          status: item.accountState,
          id: item.id
        }
        this.repasswordVisible = true
      },
    //  审核通过请求
      async getList() {
        this.loading = true
        try{
          const res = await getAuditSubordinateGovernmentPageList(this.queryParams)
          this.loading = false
          this.tableData = res.data.rows
          this.total = res.data.total
        }catch (e) {
          this.loading = false
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .main-uniteApproved {
    height: calc(100% - 70px);
    padding: 50px 40px;
    .top-search-add {
      overflow: hidden;
      button {
        float: right;
      }
    }
    .bottom-table {
      margin-top: 30px;
    }
  }
</style>
